import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a8de42d = () => interopDefault(import('../src/pages/library.vue' /* webpackChunkName: "" */))
const _a8180f02 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _9af57ec2 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _c7312172 = () => interopDefault(import('../src/pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _af470080 = () => interopDefault(import('../src/pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _2c1cddee = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _3b477189 = () => interopDefault(import('../src/pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _eced12e0 = () => interopDefault(import('../src/pages/extracts.vue' /* webpackChunkName: "pages/extracts" */))
const _fd8c3bf0 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _01ff6404 = () => interopDefault(import('../src/pages/forParents.vue' /* webpackChunkName: "pages/forParents" */))
const _6ec00cf3 = () => interopDefault(import('../src/pages/forStudents.vue' /* webpackChunkName: "pages/forStudents" */))
const _6b2bb3ac = () => interopDefault(import('../src/pages/forTeachers.vue' /* webpackChunkName: "pages/forTeachers" */))
const _4bd03570 = () => interopDefault(import('../src/pages/google-analytics.vue' /* webpackChunkName: "pages/google-analytics" */))
const _fac33c18 = () => interopDefault(import('../src/pages/helpCenter.vue' /* webpackChunkName: "pages/helpCenter" */))
const _d559134a = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _910989ec = () => interopDefault(import('../src/pages/offline-fallback.vue' /* webpackChunkName: "pages/offline-fallback" */))
const _129a2a99 = () => interopDefault(import('../src/pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _48783c90 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _b455959a = () => interopDefault(import('../src/pages/savedForOffline.vue' /* webpackChunkName: "pages/savedForOffline" */))
const _558d6ea6 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7f797783 = () => interopDefault(import('../src/pages/state.vue' /* webpackChunkName: "pages/state" */))
const _8eed7d44 = () => interopDefault(import('../src/pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _689efac2 = () => interopDefault(import('../src/pages/thank_you_for_registration.vue' /* webpackChunkName: "pages/thank_you_for_registration" */))
const _3ebd55d4 = () => interopDefault(import('../src/pages/verify_email.vue' /* webpackChunkName: "pages/verify_email" */))
const _97f570f8 = () => interopDefault(import('../src/pages/category/favorites.vue' /* webpackChunkName: "pages/category/favorites" */))
const _07d8a335 = () => interopDefault(import('../src/pages/dashboard/reading-group.vue' /* webpackChunkName: "pages/dashboard/reading-group" */))
const _49c96122 = () => interopDefault(import('../src/pages/subscription/cards.vue' /* webpackChunkName: "pages/subscription/cards" */))
const _81cb41c0 = () => interopDefault(import('../src/pages/subscription/history.vue' /* webpackChunkName: "pages/subscription/history" */))
const _67bef7b8 = () => interopDefault(import('../src/pages/affiliates/_affiliateId.vue' /* webpackChunkName: "pages/affiliates/_affiliateId" */))
const _43dade52 = () => interopDefault(import('../src/pages/author/_authorHash.vue' /* webpackChunkName: "pages/author/_authorHash" */))
const _b530bcd2 = () => interopDefault(import('../src/pages/category/_name.vue' /* webpackChunkName: "pages/category/_name" */))
const _07a1c947 = () => interopDefault(import('../src/pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _7b45c8b2 = () => interopDefault(import('../src/pages/developlibraryset/_setId.vue' /* webpackChunkName: "pages/developlibraryset/_setId" */))
const _37cb1195 = () => interopDefault(import('../src/pages/developstudycourse/_syllabusId.vue' /* webpackChunkName: "pages/developstudycourse/_syllabusId" */))
const _abcd6660 = () => interopDefault(import('../src/pages/genre/_name.vue' /* webpackChunkName: "pages/genre/_name" */))
const _763c7f70 = () => interopDefault(import('../src/components/pages/LoginPage/LoginPage.vue' /* webpackChunkName: "" */))
const _6c159c08 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bloggerAuth",
    redirect: "about",
    name: "bloggerAuth"
  }, {
    path: "/library_(\\w{2})",
    component: _5a8de42d,
    name: "library_language"
  }, {
    path: "/about/",
    component: _a8180f02,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/account/",
    component: _9af57ec2,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/affiliates/",
    component: _c7312172,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates"
  }, {
    path: "/assessment/",
    component: _af470080,
    pathToRegexpOptions: {"strict":true},
    name: "assessment"
  }, {
    path: "/dashboard/",
    component: _2c1cddee,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/delete-account/",
    component: _3b477189,
    pathToRegexpOptions: {"strict":true},
    name: "delete-account"
  }, {
    path: "/extracts/",
    component: _eced12e0,
    pathToRegexpOptions: {"strict":true},
    name: "extracts"
  }, {
    path: "/faq/",
    component: _fd8c3bf0,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/forParents/",
    component: _01ff6404,
    pathToRegexpOptions: {"strict":true},
    name: "forParents"
  }, {
    path: "/forStudents/",
    component: _6ec00cf3,
    pathToRegexpOptions: {"strict":true},
    name: "forStudents"
  }, {
    path: "/forTeachers/",
    component: _6b2bb3ac,
    pathToRegexpOptions: {"strict":true},
    name: "forTeachers"
  }, {
    path: "/google-analytics/",
    component: _4bd03570,
    pathToRegexpOptions: {"strict":true},
    name: "google-analytics"
  }, {
    path: "/helpCenter/",
    component: _fac33c18,
    pathToRegexpOptions: {"strict":true},
    name: "helpCenter"
  }, {
    path: "/library/",
    component: _5a8de42d,
    pathToRegexpOptions: {"strict":true},
    name: "library"
  }, {
    path: "/login/",
    component: _d559134a,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/offline-fallback/",
    component: _910989ec,
    pathToRegexpOptions: {"strict":true},
    name: "offline-fallback"
  }, {
    path: "/onboarding/",
    component: _129a2a99,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding"
  }, {
    path: "/pricing/",
    component: _48783c90,
    pathToRegexpOptions: {"strict":true},
    name: "pricing"
  }, {
    path: "/savedForOffline/",
    component: _b455959a,
    pathToRegexpOptions: {"strict":true},
    name: "savedForOffline"
  }, {
    path: "/search/",
    component: _558d6ea6,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/state/",
    component: _7f797783,
    pathToRegexpOptions: {"strict":true},
    name: "state"
  }, {
    path: "/subscription/",
    component: _8eed7d44,
    pathToRegexpOptions: {"strict":true},
    name: "subscription"
  }, {
    path: "/thank_you_for_registration/",
    component: _689efac2,
    pathToRegexpOptions: {"strict":true},
    name: "thank_you_for_registration"
  }, {
    path: "/verify_email/",
    component: _3ebd55d4,
    pathToRegexpOptions: {"strict":true},
    name: "verify_email"
  }, {
    path: "/category/favorites/",
    component: _97f570f8,
    pathToRegexpOptions: {"strict":true},
    name: "category-favorites"
  }, {
    path: "/dashboard/reading-group/",
    component: _07d8a335,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-reading-group"
  }, {
    path: "/subscription/cards/",
    component: _49c96122,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-cards"
  }, {
    path: "/subscription/history/",
    component: _81cb41c0,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-history"
  }, {
    path: "/",
    component: _5a8de42d,
    name: "library"
  }, {
    path: "/affiliates/:affiliateId/",
    component: _67bef7b8,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates-affiliateId"
  }, {
    path: "/author/:authorHash?/",
    component: _43dade52,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorHash"
  }, {
    path: "/category/:name?/",
    component: _b530bcd2,
    pathToRegexpOptions: {"strict":true},
    name: "category-name"
  }, {
    path: "/collection/:id?/",
    component: _07a1c947,
    pathToRegexpOptions: {"strict":true},
    name: "collection-id"
  }, {
    path: "/developlibraryset/:setId?/",
    component: _7b45c8b2,
    pathToRegexpOptions: {"strict":true},
    name: "developlibraryset-setId"
  }, {
    path: "/developstudycourse/:syllabusId?/",
    component: _37cb1195,
    pathToRegexpOptions: {"strict":true},
    name: "developstudycourse-syllabusId"
  }, {
    path: "/genre/:name?/",
    component: _abcd6660,
    pathToRegexpOptions: {"strict":true},
    name: "genre-name"
  }, {
    path: "/access_token*",
    component: _763c7f70,
    name: "access_token"
  }, {
    path: "/state*",
    component: _763c7f70
  }, {
    path: "/:slug?/",
    component: _6c159c08,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
